import request from "@/utils/request";
// 新增工位区域
export function addArea(data) {
  return request("post", "/apm/region/plan/create", data);
}
// 删除工位区域
export function delArea(data) {
    return request("post", "/apm/region/plan/delete", data);
  }
  // 查询工位区域详情
export function getAreaInfo(data) {
    return request("post", "/apm/region/plan/findById", data);
  }
  // 查询工位区域列表
export function getAreaList(data) {
    return request("post", "/apm/region/plan/page", data);
  }
   // 更新工位区域
export function editArea(data) {
    return request("post", "/apm/region/plan/update", data);
  }
     // 保存工位布局信息
export function saveArea(data) {
  return request("post", "/apm/region/plan/seat/save", data);
}
// 查询工位布局信息
export function getAreaSeat(data) {
  return request("post", "/apm/region/plan/seat/list", data);
}
  