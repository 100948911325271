<template>
    <div id="account">
        <section class="table-search">
            <div class="search-L">
                <el-select v-model="tableParams.serviceId" placeholder="请选择服务内容" @change="reload" clearable>
                    <el-option v-for="item in serviceList" :key="item.serviceId" :label="item.serviceName"
                        :value="item.serviceId">
                    </el-option>
                </el-select>
            </div>

        </section>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe :header-cell-style="{
            background: '#FAFAFA', color: '#000000'
        }">
            <el-table-column label="区域名称" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.planName || "-" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="3D俯视图" align="center">
                <template slot-scope="scope">
                    <el-image style="width: .28rem; height: .28rem" :src="scope.row.fileUrl"
                        :preview-src-list="[scope.row.fileUrl]">
                    </el-image>
                </template>
            </el-table-column>

            <el-table-column label="归属服务内容" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.serviceName || "-" }}</span>
                </template>
            </el-table-column>
            <el-table-column width="200" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
                    <Position :shopId="tableParams.shopId" :img="scope.row.fileUrl" :serviceId="scope.row.serviceId"
                        :planId="scope.row.planId" />
                    <!-- <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <div class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </div>
        <!-- 编辑弹框 -->
        <el-dialog :visible.sync="editShow" :title="editTitle" :close-on-click-modal="false" width="40%"
            custom-class="add-dialog" @close="closeDialog">
            <el-form :model="formData" :rules="rules" ref="form-ref" label-width="120px">
                <!-- 区域名称 -->
                <el-form-item label="区域名称" prop="planName">
                    <el-input v-model.trim="formData.planName" autocomplete="off" placeholder="请填写区域名称"></el-input>
                </el-form-item>
                <!-- 归属服务内容 -->
                <el-form-item label="归属服务内容" prop="serviceId">
                    <el-select v-model="formData.serviceId" placeholder="请选择归属服务内容">
                        <el-option v-for="item in serviceList" :key="item.serviceId" :label="item.serviceName"
                            :value="item.serviceId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="3D俯视图" prop="fileId">
                    <div class="upload-btn" @click="onUpload(8, 1)">
                        <img v-if="formData.fileUrl" :src="formData.fileUrl" class="upload-img">
                        <i v-else class="el-icon-plus uploader-icon"></i>
                    </div>
                    <div style="color: #999;margin: 10px 0;font-size: 12px;width: 4rem;">*建议区域内工位大小为30X30px，图片大小为1M以内
                    </div>
                </el-form-item>
                <!-- 区域图片 -->
                <el-form-item label="区域图片" prop="regionPlanFileVoList" class="long-width">
                    <ul class="imgs">
                        <li v-for="item in fileList" class="upload-img">
                            <img :src="item.url">
                            <div class="img-del" @click="onImgDel(item)">
                                <i class="el-icon-delete"></i>
                            </div>
                        </li>
                        <li class="upload-btn" v-show="upBtnShow">
                            <i class="el-icon-plus uploader-icon" @click="onUpload(2, 2)"></i>
                        </li>
                    </ul>
                </el-form-item>
                <el-form-item label="标签" class="long-width">
                    <el-input v-model="tagName" autocomplete="off" maxlength="5" placeholder="请输入最多5个字">
                    </el-input>
                    <el-button type="primary" plain @click="addTag" v-if="tagList.length < 5"
                        style="margin-left: 10px;">新建标签</el-button>
                </el-form-item>
                <div style="display: flex;flex-wrap: wrap;margin: 10px 0;padding-left: 120px;">
                    <el-tag v-for="(tag, index) in tagList" :key="tag.name" closable @close="delTag(index)"
                        style="margin-right: 10px;">
                        {{ tag }}
                    </el-tag>

                </div>
                <div style="font-size: 12px;color: #999;margin-bottom: 10px;text-align: start;margin-left: 120px;">
                    1个区域最多可以设置5个标签</div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
            </div>
        </el-dialog>
        <!-- 图片上传组件 -->
        <UpLoad @fileIds="getFileIds" :maxlimit="maxlimit" ref="up" :fileType="fileType"></UpLoad>
    </div>
</template>

<script>
import {
    getServiceList,

} from "@/api/resource/room";
import {
    getUserRoleDel
} from "@/api/setting/user";
import {
    addArea, delArea, getAreaInfo, getAreaList, editArea
} from "@/api/resource/desk";
// import {
//     getIdServiceList,
// } from "@/api/commercial/service"
import UpLoad from "@/components/upload";
import Position from './child/Position'
import {
    getFileList
} from "@/api/common";
import {
    ObjToOpt,
} from "@/utils/utils"
import { rules } from "@/db/rules";
export default {
    components: { UpLoad, Position },
    computed: {
        upBtnShow() {
            if (this.fileList && this.fileList.length > 5) {
                return false
            } else if (!this.fileList || this.fileList.length < 5) {
                return true
            }
        },
        maxlimit() {
            if (this.fileList && this.fileList.length > 0) {
                return 5 - this.fileList.length
            } else {
                return 5
            }
        },

    },
    // 监听属性  
    watch: {
        fileList(val) {

            let arr = [];
            if (val && val.length > 0) {
                for (let i of val) {
                    arr = [...arr, i.attId]
                }
            }
            this.formData.fileIdList = arr
        },
    },
    data() {
        return {
            rules,

            ObjToOpt,
            serviceList: [],//服务列表

            // table列表
            currentPage: 1, // 当前页
            total: 0, // 总条数  
            tableData: [],
            tableParams: {
                page: 1,
                size: 10,
                merchantId: "", //商户ID
                shopId: "", //店铺ID
                serviceId: "", //服务ID
            },
            // 表单
            editShow: false, //弹框开关
            editTitle: "", //弹框姓名
            formData: {
                planId: '',
                planName: "", //q区域姓名
                fileId: "", //3d视图
                fileIdList: '',//区域图片
                serviceId: "", //服务id
                label: '',//标签
                merchantId: "", //商户id
                shopId: "", //店铺id
            },
            fileList: [],//图片列表
            fileType: 1,
            upType: "",
            imgUrl: '',
            tagName: '',
            tagList: [],

        };
    },
    created() {
        let merchantShopId = window.localStorage.getItem(
            "playOne-store-merchantShopId"
        );
        if (merchantShopId) {
            this.tableParams.shopId = JSON.parse(merchantShopId)[1];
        }
    },
    mounted() {
        this.getAreaList();
        this.getServiceList();// 【请求】商户下拉列表
    },
    methods: {

        reset() {
            this.formData = {
                planId: '',
                planName: "", //q区域姓名
                fileId: "", //3d视图
                regionPlanFileVoList: '',//区域图片
                serviceId: "", //服务id
                label: '',//标签
                merchantId: "", //商户id
                shopId: "", //店铺id
            };
            this.fileList = [];
        },
        closeDialog(){
            this.$refs["form-ref"].clearValidate();
            this.reset()
        },
        _getAreaInfo(id) {
            getAreaInfo({ planId: id }).then(res => {
                if (res.isSuccess == "yes") {
                    this.formData = res.data;
                    if (res.data.regionPlanFileVoList && res.data.regionPlanFileVoList.length > 0) {
                        res.data.regionPlanFileVoList.forEach(item => {
                            this.fileList.push({
                                attId: item.fileId,
                                url: item.fileUrl
                            })
                        })
                    }

                    this.tagList = this.formData.label ? this.formData.label.split(',') : []
                    this.$nextTick(() => {
                        this.$refs["form-ref"].clearValidate();
                    });
                }
            })
        },
        // 【请求】筛选服务内容列表
        // 【请求】服务列表
        getServiceList() {
            let data = {
                shopId: this.tableParams.shopId,
                serviceTemplateType: 5
            };
            getServiceList(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.serviceList = res.data;
                }
            });
        },

        // 【监听】筛选商户
        onMerchantSel() {
            this.tableParams.shopId = "";
            this.tableParams.serviceId = "";
            this.reload();
            if (this.tableParams.merchantId) {
                this.getTableIdShopList();
            } else {
                this.tableShopList = [];
            }
        },



        // 【监听】服务筛选
        onServiceSel() {
            this.$forceUpdate();
            this.reload();
        },



        // 【请求】区域列表
        getAreaList() {

            getAreaList(this.tableParams).then((res) => {
                if (res.data) {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                } else {
                    this.tableData = []
                    this.total = 0;
                }

            });
        },

        // 【请求】编辑工位区域
        editArea() {
            editArea(this.formData).then(res => {
                if (res.isSuccess == "yes") {
                    this.$refs["form-ref"].resetFields();
                    this.editShow = false;
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    this.reset()
                    this.reload()
                }
            })
        },

        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getAreaList();
        },

        // 【监听】表格模糊查询
        kwChange: _.debounce(function () {
            this.reload();
        }, 500),

        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getAreaList();
        },

        // 【监听】表格条数点击
        onSizeChange(size) {
            this.tableParams.size = size;
            this.reload();
        },



        // 【监听】编辑按钮
        onEdit(row) {
            console.log('row: ', row);
            this.editShow = true;
            this.editTitle = "编辑工位区域";
            this._getAreaInfo(row.planId);

        },

        // 【监听】表单提交
        onSubmit(formName) {
            this.formData.merchantId = this.tableParams.merchantId;
            this.formData.shopId = this.tableParams.shopId;
           
            this.$refs[formName].validate((valid) => {

                if (valid) {
                    this.editArea();
                } else {
                    return false;
                }
            });
        },


        // 【监听】文件上传
        onUpload(fileType, upType) {
            this.fileType = fileType;
            this.upType = upType;
            this.$refs.up.onUpload();
        },
        // 【监听】获取上传文件ID集合
        getFileIds(fileIds) {
            let data = {
                fileIds
            }
            getFileList(data).then(res => {
                if (res.isSuccess == "yes") {
                    // 缩略图
                    if (this.upType == 1) {
                        this.formData.fileId = fileIds;
                        this.formData.fileUrl = res.data[0].url;
                        this.imgUrl = res.data[0].url;

                    }
                    // 主图
                    else {
                        // this.formData.regionPlanSeatVoList = fileIds;
                        this.fileList = [...this.fileList, ...res.data]
                        console.log('fileList: ', this.fileList);
                    }

                    this.$forceUpdate();
                }
            })
        },
        onImgDel(item) {
            this.$confirm('你确定要删除吗?', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.fileList.splice(this.fileList.indexOf(item), 1);
            });
        },
        addTag() {
            if (this.tagName == '') {
                this.$message.error('请输入标签名称');
                return;
            }
            this.tagList.push(this.tagName);
            this.formData.label = this.tagList.join(',')
            this.tagName = ''
        },
        delTag(index) {
            console.log('index: ', index, this.tagList);
            this.tagList.splice(index, 1);
            this.formData.label = this.tagList.join(',')

        }


    },
};
</script>

<style lang="scss" scoped>
#account {
    padding: .15rem;
    box-sizing: border-box;
}

.table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: .15rem;
}

.search-L {
    display: flex;
}

.table-footer {
    margin-top: .1rem;
    display: flex;
    justify-content: flex-end;
}

.upload-btn {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}

.upload-btn:hover {
    border-color: #409EFF;
}

.uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
}

.upload-img {
    display: inline-block;
    border: 1px dashed #d9d9d9;
    margin: 0 .1rem .1rem 0;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    position: relative;

    >img {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
}

.tip {
    color: red;
    display: flex;
}

.imgs {
    display: flex;
    flex-wrap: wrap;
}

.img-del {
    width: 1rem;
    height: 1rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    visibility: hidden;

    >.el-icon-delete {
        font-size: 28px;
        color: #fff;
    }
}

.upload-img:hover .img-del {
    visibility: visible;
}

.long-width /deep/.el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px;
    width: 4rem;
    display: flex;


}
</style>