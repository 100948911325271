<template>
  <div style="display: inline-block;margin: 0 20px;">
    <el-button type="text" size="medium" @click.stop="onEdit">配置工位</el-button>
    <el-dialog :visible.sync="editShow" :title="'配置工位'" :close-on-click-modal="false" width="90%">
      <div style="display: flex;width: 100%;">
        <div class="left">
          <span>1F工位区域</span>
          <div style="margin: 10px 0;color:red;">*拖拽工位至平面图区域</div>
          <div>
            <div v-for="(item, index) in roomList" :key="index" style="display: inline-block;margin:0 5px 5px 0 ;">
              <div
                style="width: 60px;height: 60px;border: 1px solid #ccc;display: flex;align-items: center;justify-content: center;cursor: pointer;"
                draggable="true" @dragstart="dragStart($event, item)">{{ item.roomName }}</div>
            </div>
          </div>
        </div>
        <div class="center">
          <div style="margin: 10px 0;color:red;">*长按图片工位区域可删除工位</div>
      
          <div id="app">
            <!-- 区域容器 -->
            <div class="view_box">
              <div class="red-box"
                :style="`width:${imageWidth * scaleVal / 100}px;height:${imageHeight * scaleVal / 100}px;background-image: url(${img});`"
                @drop.prevent="drop" @dragover.prevent="dragOver">
                <!-- 渲染工位矩形 -->
                <div v-for="(box, index) in form.regionPlanSeatVoList" :key="index" class="black-box"
                  :style="{ top: box.top * scaleVal / 100 + 'px', left: box.left * scaleVal / 100 + 'px', width: 60 * scaleVal / 100 + 'px', height: 60 * scaleVal / 100 + 'px', fontSize: 12 * scaleVal / 100 + 'px' }"
                  @mousedown="startLongPress(index)" @mouseup="cancelLongPress" @mouseleave="cancelLongPress"
                  draggable="true" @dragstart="dragBoxStart(index, $event)" @dragend="dragBoxEnd">
                  {{ box.currentInfo.roomName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div style="height: 20px;width: 300px;margin-bottom: 20px">
            <el-button type="primary" @click="showSlider=!showSlider">预览</el-button>
            <el-button type="primary" @click="submit">保存</el-button>
          </div>
          <el-slider v-model="scaleVal" :max="200" v-if="showSlider"></el-slider>
        </div>

      </div>

    </el-dialog>

  </div>
</template>

<script>
import {
  getRoomList,
} from "@/api/resource/room";
import { saveArea, getAreaSeat } from '@/api/resource/desk'
export default {
  props: {
    shopId: {
      type: [Number, String],
      default: "",
    },
    planId: {
      type: [Number, String],
      default: "",
    },
    serviceId: {
      type: [Number, String],
      default: "",
    },
    img: {
      type: String,
      default: "",
    }
  },
  watch: {
    serviceId(newVal) {
      this.tableParams.serviceId = newVal
      this.form.serviceId = newVal
    },
  },
  data() {
    return {
      tableParams: {
        page: 1,
        size: 0,
        shopId: "",
        serviceId: this.serviceId
      },
      roomList: [],
      editShow: false,
      dragOffset: { x: 0, y: 0 }, // 用于存储拖动时的偏移量
      draggingIndex: null, // 当前拖拽的矩形的索引
      longPressTimeout: null, // 用于记录长按的定时器
      isNewBox: false, // 标识是否是从外部拖拽进来的新矩形
      currentDrag: {},//记录当前拖拽得元素的工位信息
      imageWidth: 0,
      imageHeight: 0,
      scaleVal: 100,
      form: {
        planId: '',
        serviceId: '',
        regionPlanSeatVoList: []
      },
      showSlider: false,

    };
  },
  created() {
    this.form.planId = this.planId

  },

  methods: {


    // --------------------------------------交互逻辑-------------------
    // 当开始拖动外部黑色矩形时，记录偏移量并标记为新矩形
    dragStart(event, item) {
      if (this.scaleVal != 100) {
        this.$message.error('请将预览缩放条拖至100，再进行工位配置')
        return
      }
      this.dragOffset.x = event.offsetX;
      this.dragOffset.y = event.offsetY;
      this.currentDrag = item
      this.isNewBox = true;
    },
    // 当开始拖动红色容器内的矩形时，记录偏移量及索引
    dragBoxStart(index, event) {
      if (this.scaleVal != 100) {
        this.$message.error('请将预览缩放条拖至100，再进行工位配置')
        return
      }
      this.draggingIndex = index;
      this.isNewBox = false;
      // 计算鼠标点击位置与矩形左上角的偏移量
      this.dragOffset.x = event.offsetX;
      this.dragOffset.y = event.offsetY;
    },
    // 拖拽过程中更新矩形位置
    dragOver(event) {
      if (this.draggingIndex !== null) {
        const redBox = this.$el.querySelector('.red-box').getBoundingClientRect();
        const top = event.clientY - redBox.top - this.dragOffset.y;
        const left = event.clientX - redBox.left - this.dragOffset.x;

        // 计算工位的宽高
        const boxWidth = 60 * this.scaleVal / 100; // 工位宽度
        const boxHeight = 60 * this.scaleVal / 100; // 工位高度

        // 确保工位不超过边界
        const newTop = Math.max(0, Math.min(top, redBox.height - boxHeight));
        const newLeft = Math.max(0, Math.min(left, redBox.width - boxWidth));

        // 更新工位位置，同时保持 currentInfo
        const currentSeat = this.form.regionPlanSeatVoList[this.draggingIndex];
        this.$set(this.form.regionPlanSeatVoList, this.draggingIndex, {
          top: newTop,
          left: newLeft,
          currentInfo: currentSeat.currentInfo // 保持 currentInfo
        });
      }
    },

    // 当拖拽结束时，如果是新矩形则添加，否则重置拖拽索引
    drop(event) {
      console.log(event)
      if (this.isNewBox) {
        const rect = event.target.getBoundingClientRect();
        const top = event.clientY - rect.top - this.dragOffset.y;
        const left = event.clientX - rect.left - this.dragOffset.x;

        this.form.regionPlanSeatVoList.push({ top, left, currentInfo: this.currentDrag });
        const index = this.roomList.findIndex(item => item.roomId == this.currentDrag.roomId)
        this.roomList.splice(index, 1)

      }
      this.draggingIndex = null;
      this.isNewBox = false;
    },
    // 当拖拽结束时，重置拖拽索引
    dragBoxEnd() {
      this.draggingIndex = null; // 重置拖拽索引
    },
    // 开始长按检测
    startLongPress(index) {
      this.longPressTimeout = setTimeout(() => {
        this.roomList.unshift(this.form.regionPlanSeatVoList[index].currentInfo)
        this.form.regionPlanSeatVoList.splice(index, 1); // 长按后删除矩形
      }, 1000); // 设置长按时间为 1 秒
    },
    // 取消长按操作
    cancelLongPress() {
      clearTimeout(this.longPressTimeout); // 清除定时器
    },
    // ------------------------------------------加载逻辑--------------------------------------------
    // 【请求】房间列表
    getRoomList() {
      let data = this.tableParams;
      getRoomList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.roomList = res.data.list;
          this.total = res.data.total;
          this.getAreaSeat()
        }
      });
    },
    onEdit() {
      console.log(this.tableParams)
      this.editShow = true
      this.tableParams.shopId = this.shopId
      this.getRoomList(); // 【请求】房间列表
      this.loadImage();



    },
    loadImage() {
      const imgObj = new Image();
      imgObj.src = this.img;
      imgObj.onload = () => {
        console.log(imgObj.width, imgObj.height)
        this.imageWidth = imgObj.width;
        this.imageHeight = imgObj.height;
      };
    },

    // 点击保存
    submit() {
      this.form.regionPlanSeatVoList.forEach(item => {
        item.roomId = item.currentInfo.roomId
        item.axisX = item.left
        item.axisY = item.top
      })
      console.log(this.form.regionPlanSeatVoList)

      saveArea(this.form).then(res => {
        if (res.isSuccess == 'yes') {
          this.$message.success('保存成功')
          this.editShow = false

        }
      })
    },
    // 查询布局信息
    getAreaSeat() {
      const params = {
        planId: this.planId
      }
      getAreaSeat(params).then(res => {
        this.form.regionPlanSeatVoList = res.data

        if (res.data && res.data.length > 0) {
          this.form.regionPlanSeatVoList.forEach(item => {
            item.left = item.axisX
            item.top = item.axisY
            const index = this.roomList.findIndex(room => room.roomId == item.roomId);
            if (index !== -1) {
              this.roomList.splice(index, 1);
            }
          })
        } else {
          this.form.regionPlanSeatVoList = []
        }
        console.log(this.form.regionPlanSeatVoList)
      })
    }

  },
};
</script>

<style scoped>
.left {
  width: 20%;
  border: 1px solid #ccc;
  height: 500px;
  text-align: start;
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
}

.center {
  width: 83%;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.view_box {
  width: 375px;
  height: 762px;
  overflow: auto;
  border: 1px solid #ccc;
  position: relative;

}

.box {
  width: 50px;
  height: 50px;
  background-color: #ccc;
  position: absolute;
  cursor: move;
  font-size: 12px;
}

.draggable-box {
  width: 50px;
  height: 50px;
  background-color: black;
  margin-bottom: 20px;
  cursor: pointer;
}

.draggable-box:hover {
  background-color: blue;
}

.dragging {
  overflow: auto;
  border: 2px solid blue;
}

.red-box {

  margin-bottom: 20px;
  background-size: cover;
  /* 或者使用 contain */
  background-repeat: no-repeat;
  background-position: top left;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.black-box {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  position: absolute;
  cursor: move;
  font-size: 12px;
}

.draggable-box {
  width: 50px;
  height: 50px;
  background-color: black;
  margin-bottom: 20px;
  cursor: pointer;
}

.draggable-box:hover {
  background-color: blue;
}

.dragging {}

.black-box {

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  position: absolute;
  cursor: move;

}

.draggable-box {
  width: 50px;
  height: 50px;
  background-color: black;
  margin-bottom: 20px;
  cursor: pointer;
}

.black-box {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  position: absolute;
  cursor: move;
  font-size: 12px;
}

.draggable-box {
  width: 50px;
  height: 50px;
  background-color: black;
  margin-bottom: 20px;
  cursor: pointer;
}
</style>